<template>
  <div>
    <div v-if="!tplCreate" style="width: 100%;text-align: right">
      <el-button @click="dialogVisible = true" size="medium" type="primary" icon="el-icon-edit">创建新的活动</el-button>
    </div>
    <el-dialog title="创建活动" destroy-on-close :visible.sync="dialogVisible" width="60rem" @close="close">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="选择模式"></el-step>
        <el-step title="基础信息"></el-step>
        <el-step title="页面设计"></el-step>
      </el-steps>
      <div v-if="active === 0" class="flex-def flex-cCenter flex-zCenter" style="padding-bottom: 2rem;margin-top: 2rem;font-size: 1rem;font-weight: 600;color: #888888;">
        <choose_mode @change="modeChoose"></choose_mode>
      </div>
      <div v-if="active === 1" v-loading="saveLoading" class="flex-def flex-cCenter flex-zCenter" style="margin-top: 2rem;font-size: 1rem;font-weight: 600;color: #888888;">
        <mode1 v-if="mode === 1" @next="next"></mode1>
        <mode2 v-if="mode === 2" @next="next"></mode2>
        <mode3 v-if="mode === 3" @next="next"></mode3>
        <mode4 v-if="mode === 4" @next="next"></mode4>
        <mode5 v-if="mode === 5" @next="next"></mode5>
        <mode6 v-if="mode === 6" @next="next"></mode6>
      </div>
      <div v-if="active === 2" class="flex-def flex-cCenter flex-zCenter" style="padding-bottom: 2rem;margin-top: 2rem;font-size: 1rem;font-weight: 600;color: #888888;">
        <div @click="diyHands" class="y-pointer flex-def flex-zCenter flex-cCenter" style="border-radius: .5rem;height: 10rem;width: 8rem;background-color: #f8f8f8">
          自定义设计
        </div>
        <div @click="diyTpl" class="y-pointer flex-def flex-zCenter flex-cCenter" style="margin-left: 2rem;border-radius: .5rem;height: 10rem;width: 8rem;background-color: #f8f8f8">
          选择模版
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mode1 from "@/view/active/add/mode1";
import mode2 from "@/view/active/add/mode2";
import mode3 from "@/view/active/add/mode3";
import mode4 from "@/view/active/add/mode4";
import choose_mode from "@/view/active/add/choose_mode";
import mode5 from "@/view/active/add/mode5";
import mode6 from "@/view/active/add/mode6";
export default {
  name: "index",
  components:{
    mode1,
    mode2,
    mode3,
    mode4,
    mode5,
    mode6,
    choose_mode
  },
  props:{
    tplCreate:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      dialogVisible:false,
      mode:0,
      active:0,
      active_id:0,
      callback:false,
      saveLoading:false,
    }
  },
  methods:{
    createActive(callback){
      this.dialogVisible = true;
      this.active = 1;
      this.callback = callback;
    },
    modeChoose(mode){
      this.mode = mode;
      this.active = 1;
    },
    next(e){
      this.active_id = e;
      if(this.mode === 2){
        //视频模式直接进入编辑界面
        this.$router.push({
          name:"activeEdit",
          params:this.$route.params,
          query:{
            id: this.active_id
          }
        });
        return;
      }
      if(this.tplCreate){
        // this.dialogVisible = false;
        this.saveLoading = true;
        this.callback && this.callback(e);
        return
      }
      this.active += 1;
    },
    close(){
      if(this.tplCreate){
        this.active = 0;
        this.active_id = 0;
        this.mode = 0;
        this.$emit("close")
        return;
      }
      if(this.active === 2){
        this.active = 0;
        this.$router.push({
          name:"activeEdit",
          params:this.$route.params,
          query:{
            id: this.active_id
          }
        });
        return;
      }
      this.active = 0;
      this.active_id = 0;
    },
    diyTpl(){
      this.$router.push({
        name:"tplModeList",
        params:{
          ...this.$route.params,
          mode:this.mode
        },
        query:{
          active_id:this.active_id
        }
      })
    },
    diyHands(){
      this.$router.push({
        name:"activeEdit",
        params:this.$route.params,
        query:{
          id:this.active_id,
          tab:"design"
        }
      })
    },
  }
}
</script>

<style scoped>

</style>