<template>
  <y_layout>
    <div class="flex-def flex-zBetween" style="padding: 1rem;">
      <el-button @click="$router.push({
      name:'tplMode',
      params:$route.params,
      })">活动模版库
      </el-button>
      <add></add>
    </div>
    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">
      <el-collapse accordion>
        <el-collapse-item>
          <template #title><i style="margin-left: 1rem;font-size: 1rem;font-weight: 600;margin-right: .5rem"
                              class="el-icon-search"></i>筛选条件
          </template>
          <div class="flex-def flex-zCenter flex-cCenter">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
              <el-form-item label="活动名称">
                <el-input v-model="searchForm.title" placeholder="活动名称"></el-input>
              </el-form-item>
              <el-form-item label="活动状态">
                <el-select v-model="searchForm.time_status">
                  <el-option label="全部" :value="0"></el-option>
                  <el-option label="未开始" :value="1"></el-option>
                  <el-option label="已开始" :value="2"></el-option>
                  <el-option label="已结束" :value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker v-model="searchForm.createtime" type="daterange" range-separator="至"
                                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button-group>
                  <el-button @click="search" size="medium" type="primary" icon="el-icon-search">搜索</el-button>
                  <el-button @click="resetSearch" size="medium" native-type="reset">重置</el-button>
                </el-button-group>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <div v-loading="loading" style="margin-top: 1rem">
      <div style="background-color: #FFFFFF;margin-bottom: 1rem;border-radius: .5rem" v-for="(item,key) in list" :key="key">
        <div style="padding: 1rem;border-bottom: 1px solid #ededed" class="flex-def flex-zBetween flex-cCenter">
          <div class="flex-def flex-cCenter">
            <div>编号：{{ item.active.id }}</div>
            <div style="margin-left: 1rem;max-width: 30rem;" class="text-ellipsis">
              <span style="color: red;font-weight: 600">【{{ item.active ? modeText(item.active.mode) : '' }}】</span>
              {{ item.active.title }}
            </div>
            <div v-if="item.active && item.active.agent" class="y-desc" style="font-size: 1.2rem">【所属代理：{{item.active.agent.title}}】</div>
          </div>
          <div>
            <el-switch size="large" @change="statusChange(item)" v-model="item.active.status" active-color="#31af71"
                       inactive-color="#e51a27"></el-switch>
            <el-button style="margin-left: 1rem" @click="$router.push({
                        name:'activeEdit',
                        params:$route.params,
                        query:{
                          id:item.active.id,
                          tab:'record'
                        }
                        })" type="success" size="small">查看
            </el-button>
            <el-button @click="$router.push({
                        name:'activeEdit',
                        params:$route.params,
                        query:{
                          id:item.active.id
                        }
                        })" type="warning" size="small">编辑
            </el-button>
            <el-popconfirm style="margin-left: 1rem" @confirm="del(item.active.id)" title="确认要删除？">
              <el-button slot="reference" type="danger" size="small">删除</el-button>
            </el-popconfirm>
          </div>
        </div>
        <div class="flex-def flex-cCenter" style="padding: 1rem">
          <div style="background-color: #f8f8f8;width: 7rem;height: 8rem;border-radius: .5rem;overflow: hidden">
            <img :src="item.active.share_pic | tomedia" style="height: 100%;width: 100%;">
          </div>
          <div class="flex-def flex-zBetween" style="margin-left: 1rem;width: 70%">
            <div style="font-size: 1rem;height: 7rem;justify-content: space-between" class="y-desc flex-def flex-zTopBottom" >
              <div class="flex-def flex-cCenter">
                <img :src="'red-time-start' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>开始时间：</span>
                <span>{{item.active.time_start | date}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-time-end' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>结束时间：</span>
                <span>{{item.active.time_end | date}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-add' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>创建时间：</span>
                <span>{{item.active.created_at | date}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-edit' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>上次编辑：</span>
                <span>{{item.active.updated_at | date}}</span>
              </div>
            </div>
            <div style="font-size: 1rem;height: 7rem;justify-content: space-between" class="y-desc flex-def flex-zTopBottom" >
              <div class="flex-def flex-cCenter">
                <img :src="'red-get-total' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>收入总额：</span>
                <span>{{item.total_order_fee}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-out-total' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>支出总额：</span>
                <span>{{item.total_reward}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-buy-total' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>购买人数：</span>
                <span>{{item.active.apply_num}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-back' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>返利人数：</span>
                <span>{{item.reward_num}}</span>
              </div>
            </div>
            <div style="font-size: 1rem;height: 7rem;justify-content: space-between" class="y-desc flex-def flex-zTopBottom" >
              <div class="flex-def flex-cCenter">
                <img :src="'red-award' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>一级佣金：</span>
                <span>{{item.invite}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-award-2' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>二级佣金：</span>
                <span>{{item.invite_2}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-packet' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>员工红包：</span>
                <span>{{item.clerk}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-packet-2' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>奖励红包：</span>
                <span>{{item.draw}}</span>
              </div>
            </div>
            <div style="font-size: 1rem;height: 7rem;justify-content: space-between" class="y-desc flex-def flex-zTopBottom" >
              <div class="flex-def flex-cCenter">
                <img :src="'red-level' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>代理佣金：</span>
                <span>{{item.agent_award}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-share' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>分享红包：</span>
                <span>{{item.share}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-task' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>分享总量：</span>
                <span>{{item.active.join_num}}</span>
              </div>
              <div class="flex-def flex-cCenter">
                <img :src="'red-visit' | elIcon" style="height: 1.2rem;width: 1.2rem;margin-right: .5rem">
                <span>曝光总量：</span>
                <span>{{item.active.view_num}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                     :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
      </el-pagination>
    </div>
    <!--    <el-card shadow="hover" :body-style="{padding:'1rem 1rem'}" style="width: 100%;">-->


    <!--      <el-table v-loading="loading" :data="list" border style="width: 100%">-->
    <!--        <el-table-column prop="active.id" label="编号" width="80"></el-table-column>-->
    <!--        <el-table-column prop="active.title" label="活动名称" width="200"></el-table-column>-->
    <!--        <el-table-column prop="active.mode"-->
    <!--                         :formatter="(item)=>item.active ? modeText(item.active.mode) : ''" label="活动类型"-->
    <!--                         width="100"></el-table-column>-->
    <!--        <el-table-column prop="active.fee" label="金额" width="80"></el-table-column>-->
    <!--        <el-table-column label="开始时间" width="180">-->
    <!--          <template #default="s">{{ s.row.active.time_start | date }}</template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="结束时间" width="180">-->
    <!--          <template #default="s">{{ s.row.active.time_end | date }}</template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="活动状态" width="80" align="center">-->
    <!--          <template #default="scope">-->
    <!--            <el-switch @change="statusChange(scope.row)" v-model="scope.row.active.status" active-color="#13ce66"-->
    <!--                       inactive-color="#ff4949"></el-switch>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="购买人数" prop="active.apply_num" width="80"></el-table-column>-->
    <!--        <el-table-column label="返利总额" prop="total_reward" width="120"></el-table-column>-->
    <!--        <el-table-column label="分享总量" prop="active.join_num" width="80"></el-table-column>-->
    <!--        <el-table-column label="曝光总量" prop="active.view_num" width="120"></el-table-column>-->
    <!--        <el-table-column show-overflow-tooltip label="代理商" prop="active.agent.title" width="120"></el-table-column>-->
    <!--        <el-table-column label="创建时间" width="180">-->
    <!--          <template #default="s">{{ s.row.active.created_at | date }}</template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column label="上次编辑" width="180">-->
    <!--          <template #default="s">{{ s.row.active.updated_at | date }}</template>-->
    <!--        </el-table-column>-->
    <!--        <el-table-column fixed="right" label="操作" width="130">-->
    <!--          <template #default="s">-->
    <!--            <el-button @click="$router.push({-->
    <!--            name:'activeEdit',-->
    <!--            params:$route.params,-->
    <!--            query:{-->
    <!--              id:s.row.active.id,-->
    <!--              tab:'record'-->
    <!--            }-->
    <!--            })" type="text" size="small">查看-->
    <!--            </el-button>-->
    <!--            <el-button @click="$router.push({-->
    <!--            name:'activeEdit',-->
    <!--            params:$route.params,-->
    <!--            query:{-->
    <!--              id:s.row.active.id-->
    <!--            }-->
    <!--            })" type="text" size="small">编辑-->
    <!--            </el-button>-->
    <!--            <el-popconfirm style="margin-left: 1rem" @confirm="del(s.row.active.id)" title="确认要删除？">-->
    <!--              <el-button slot="reference" style="color: red" type="text" size="small">删除</el-button>-->
    <!--            </el-popconfirm>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--        <template #append>-->
    <!--          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"-->
    <!--                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">-->
    <!--          </el-pagination>-->
    <!--        </template>-->
    <!--      </el-table>-->
    <!--    </el-card>-->
  </y_layout>
</template>

<script>
import y_layout from "@/components/common/y_layout";
import add from "@/view/active/add/index";

export default {
  name: "index",
  components: {
    y_layout,
    add
  },
  data() {
    return {
      total: 0,
      page: 1,
      list: [],
      searchForm: {
        title: "",
        createtime: [],
        time_status: 0,
      },
      loading: false,
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    modeText(mode) {
      let str = "";
      switch (parseInt(mode)) {
        case 1:
          str = "通用模式";
          break;
        case 2:
          str = "视频模式";
          break;
        case 3:
          str = "单/多商家极差模式";
          break;
        case 4:
          str = "拼团模式";
          break;
        case 5:
          str = "免费模式"
          break;
        case 6:
          str = "激活码模式"
          break;
      }
      return str;
    },
    del(id) {
      this.$api.active.del({id}).then(() => {
        this.$message.success("操作成功");
        this.search()
      })
    },
    statusChange(item) {
      this.$api.active.update(item.active).then()
    },
    resetSearch() {
      this.searchForm = {
        title: "",
        createtime: [],
        time_status: 0,
      }
      this.search();
    },
    search() {
      this.loading = true;
      this.$api.active.search({
        page: this.page,
        title: this.searchForm.title,
        time_status: this.searchForm.time_status,
        time_start: this.searchForm.createtime[0],
        time_end: this.searchForm.createtime[1],
      }).then(res => {
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e) {
      this.page = e;
      this.search();
    }
  }
}
</script>

<style scoped>

</style>